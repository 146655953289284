// 遗产要素单体或局部的测绘图
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'PMHLMMC',
                label: '名称'
            }, {
                prop: 'YCYSMC',
                label: '遗产要素'
            }, {
                prop: 'SYT',
                label: '示意图',
                showImg: true,
                class: 'noOpacity'
            }],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
};
export default selfData;